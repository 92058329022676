export class Counter {
  private items: NodeListOf<Element>;
  private animationFired: boolean;

  constructor(private selector: string) {
    this.items = document.querySelectorAll(selector);
    this.animationFired = false;
    this.initialize();
  }

  private initialize(): void {
    if (this.items.length === 0) return;
    this.items.forEach((item) => this.initScrollSpy(item));
  }

  private counter(): void {
    this.animationFired = true;
    const counters = document.querySelectorAll('[data-counter]');
    const speed = 200;

    if (counters.length > 0) {
      const format = (num: number): string =>
        String(num).replace(/(?!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1 ');

      counters.forEach((counter: any) => {
        const animate = (): void => {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          const value = +counter.getAttribute('data-counter')!;
          const data = +counter.innerText;

          const time = value / speed;
          if (data < value) {
            counter.innerText = Math.ceil(data + time).toString();
            setTimeout(animate, 10);
          } else {
            counter.innerText = format(value);
          }
        };

        animate();
      });
    }
  }

  private initScrollSpy(selector: Element): void {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (!this.animationFired) {
            if (entry.isIntersecting) {
              selector.classList.add('started');
              this.counter();
            }
          }
        });
      },
      {
        rootMargin: '-25% 0px',
      }
    );
    observer.observe(selector);
  }
}
